export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは7月より%d点上がりました。暑い季節も良い生活習慣ができていますね。<br>
      9月13日から<b>Walk チーム対抗戦 2nd</b>が開催。9月12日までエントリー受付！<br>
      仲間を集めて豪華賞品を目指しながら、スポーツの秋を楽しもう♪
      また中旬からは、個人チャレンジのエントリーも開始します！`,
      down: `生活習慣スコアは7月より%d点下がりました。分類別評価でどの項目に変化があったのか振り返ってみましょう。<br>
      9月13日から<b>Walk チーム対抗戦 2nd</b>が開催。9月12日までエントリー受付！<br>
      仲間を集めて豪華賞品を目指しながら、スポーツの秋を楽しもう♪
      また中旬からは、個人チャレンジのエントリーも開始します！`,
      equal100: `生活習慣スコアは7月に引き続き<span class="good">満点</span>です。暑さに負けず、大変すばらしいです！<br>
      9月13日から<b>Walk チーム対抗戦 2nd</b>が開催。9月12日までエントリー受付！<br>
      仲間を集めて豪華賞品を目指しながら、スポーツの秋を楽しもう♪
      また中旬からは、個人チャレンジのエントリーも開始します！`,
      equal: `生活習慣スコアは7月と同じ点数です。分類別評価で項目ごとに振り返ってみましょう。<br>
      9月13日から<b>Walk チーム対抗戦 2nd</b>が開催。9月12日までエントリー受付！<br>
      仲間を集めて豪華賞品を目指しながら、スポーツの秋を楽しもう♪
      また中旬からは、個人チャレンジのエントリーも開始します！`,
    },
    steps: {
      up: `歩数スコアは7月より%d点上がりました。<br>
      10分歩くと約1000歩と言われています。<br>
      日々の歩数を振り返りながら、この調子で歩いていきましょう。`,
      down: `歩数スコアは7月より%d点下がりました。<br>
      10分歩くと約1000歩と言われています。<br>
      10分まとめて、もしくは5分×２回歩くだけで、今より1000歩歩数が増やせますよ。`,
      equal: `歩数スコアは7月と同じです。<br>
      10分歩くと約1000歩と言われています。<br>
      10分まとめて、もしくは5分×２回歩くだけで、今より1000歩歩数が増やせますよ。`,
    },
    exercise: {
      up: `運動のスコアは7月より%d点上がりました。<br>
      徐々に涼しくなり、運動しやすい時期になりますね。<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a>の興味があるテーマを実践してみるのも良いかもしれないです。`,
      down: `運動のスコアは7月より%d点下がりました。<br>
      徐々に涼しくなり、運動しやすい時期になりますね。<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a>の興味があるテーマを実践してみるのも良いかもしれないです。`,
      equal: `運動のスコアは7月と同じです。<br>
      徐々に涼しくなり、運動しやすい時期になりますね。<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 minutes</a>の興味があるテーマを実践してみるのも良いかもしれないです。`,
    },
    meal: {
      up: `食事のスコアは7月より%d点上がりました。<br>
      夏から秋の変わり目に体調を崩しやすい方は、「保温」と「栄養摂取」を心がけて。温かい飲み物や食べ物で体の芯から温めましょう。
      `,
      down: `食事のスコアは7月より%d点下がりました。<br>
      夏から秋の変わり目に体調を崩しやすい方は、「保温」と「栄養摂取」を心がけて。温かい飲み物や食べ物で体の芯から温めましょう。
      `,
      equal: `食事のスコアは7月と同じです。<br>
      夏から秋の変わり目に体調を崩しやすい方は、「保温」と「栄養摂取」を心がけて。温かい飲み物や食べ物で体の芯から温めましょう。
      `,
    },
    drinking: {
      up: `飲酒のスコアは7月より%d点上がりました。<br>
      夏場もお酒と上手くお付き合いできていますね。食欲の秋も引き続き、この調子で♪
      `,
      down: `飲酒のスコアは7月より%d点下がりました。<br>
      飲み過ぎは体内リズムを乱す原因に。寝る4～5時間前までに適量を飲み終える日を作ってみましょう。
      `,
      equal: `飲酒のスコアは7月と同じです。<br>
      飲み過ぎは体内リズムを乱す原因に。寝る4～5時間前までに適量を飲み終える日を作ってみましょう。
      `,
    },
    sleep: {
      up: `睡眠のスコアは7月より%d点上がりました。<br>
      疲れているはずなのに、頭が冴えて眠れないときは、こちらの<a href="https://go.andwell.jp/library/face1c9c-5f1b-11ec-9728-06d158a16ffc">コラム</a>の<b>「4－7－8呼吸法」</b>を是非試してみて下さい。`,
      down: `睡眠のスコアは7月より%d点下がりました。<br>
      疲れているはずなのに、頭が冴えて眠れないときは、こちらの<a href="https://go.andwell.jp/library/face1c9c-5f1b-11ec-9728-06d158a16ffc">コラム</a>の<b>「4－7－8呼吸法」</b>を是非試してみて下さい。`,
      equal: `睡眠のスコアは7月と同じです。<br>
      疲れているはずなのに、頭が冴えて眠れないときは、こちらの<a href="https://go.andwell.jp/library/face1c9c-5f1b-11ec-9728-06d158a16ffc">コラム</a>の<b>「4－7－8呼吸法」</b>を是非試してみて下さい。`,
    },
    stress: {
      up: `ストレスのスコアは7月より%d点上がりました。<br>
      気分よく過ごせている日が先月よりも多いです。自分に合ったストレス発散方法はありますか？`,
      down: `ストレスのスコアは7月より%d点下がりました。<br>
      こまめにストレッチをする、ぐっすり寝るなど自分に合ったストレス発散方法はありますか？`,
      equal: `ストレスのスコアは7月と同じです。<br>
      こまめにストレッチをする、ぐっすり寝るなど自分に合ったストレス発散方法はありますか？`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since July. You have a good lifestyle even though it's hot.
      The <b>Walk Team Competition 2nd Round</b> will start on September 13. Now accepting entries until September 12! 
      Gather your friends, aim for luxury prizes, and enjoy fall, the season of sports.
      Applications for the Individual Challenge will also start in mid-September!`,
      down: `Your lifestyle score has gone down %d pts since July. Use the categorized ratings to review what items have changed.
      The <b>Walk Team Competition 2nd Round</b> will start on September 13. Now accepting entries until September 12! 
      Gather your friends, aim for luxury prizes, and enjoy fall, the season of sports.
      Applications for the Individual Challenge will also start in mid-September!`,
      equal100: `Your lifestyle score is the <span class="good">maximum</span> again, the same as July. You're doing fantastic despite the heat!
      The <b>Walk Team Competition 2nd Round</b> will start on September 13. Now accepting entries until September 12! 
      Gather your friends, aim for luxury prizes, and enjoy fall, the season of sports.
      Applications for the Individual Challenge will also start in mid-September!`,
      equal: `Your lifestyle score is the same as July. Use the categorized ratings to review your progress item by item.
      The <b>Walk Team Competition 2nd Round</b> will start on September 13. Now accepting entries until September 12! 
      Gather your friends, aim for luxury prizes, and enjoy fall, the season of sports.
      Applications for the Individual Challenge will also start in mid-September!`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since July.
      It's said that a 10-minute walk is about 1,000 steps. 
      Review your daily steps and keep up the pace.`,
      down: `Your steps score has gone down %d pts since July. 
      It's said that a 10-minute walk is about 1,000 steps. 
      Just do a 10-minute walk, or two 5-minute walks, and you'll get 1,000 more steps.`,
      equal: `Your steps score is the same as July. 
      It's said that a 10-minute walk is about 1,000 steps. 
      Just do a 10-minute walk, or two 5-minute walks, and you'll get 1,000 more steps.`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since July.
      It's getting cooler and easier to exercise. You might also want to practice any theme from <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 Minutes</a> that interests you.`,
      down: `Your exercise score has gone down %d pts since July.
      It's getting cooler and easier to exercise. You might also want to practice any theme from <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 Minutes</a> that interests you.`,
      equal: `Your exercise score is the same as July. 
      It's getting cooler and easier to exercise. You might also want to practice any theme from <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">Team 10 Minutes</a> that interests you.`,
    },
    meal: {
      up: `Your food score has gone up %d pts since July.
      If you get sick easily during the change from summer to fall, remember to stay warm and get plenty of nutrients. Warm yourself with warm drinks and food.`,
      down: `Your food score has gone down %d pts since July.
      If you get sick easily during the change from summer to fall, remember to stay warm and get plenty of nutrients. Warm yourself with warm drinks and food.`,
      equal: `Your food score is the same as July. 
      If you get sick easily during the change from summer to fall, remember to stay warm and get plenty of nutrients. Warm yourself with warm drinks and food.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since July.
      You did a good job controlling how much alcohol you drank this summer. Keep it up this fall, the season of food.`,
      down: `Your alcohol score has gone down %d pts since July.
      Drinking too much can disturb your body's rhythm. Plan some days to drink an appropriate amount no less than four to five hours before you sleep.`,
      equal: `Your alcohol score is the same as July. 
      Drinking too much can disturb your body's rhythm. Plan some days to drink an appropriate amount no less than four to five hours before you sleep.`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since July.
      If you don't feel drowsy and you have trouble sleeping even though you should be tired, try the <b>4-7-8 breathing technique</b> in this <a href="https://go.andwell.jp/library/face1c9c-5f1b-11ec-9728-06d158a16ffc">column</a>.`,
      down: `Your sleep score has gone down %d pts since July.
      If you don't feel drowsy and you have trouble sleeping even though you should be tired, try the <b>4-7-8 breathing technique</b> in this <a href="https://go.andwell.jp/library/face1c9c-5f1b-11ec-9728-06d158a16ffc">column</a>.`,
      equal: `Your sleep score is the same as July. 
      If you don't feel drowsy and you have trouble sleeping even though you should be tired, try the <b>4-7-8 breathing technique</b> in this <a href="https://go.andwell.jp/library/face1c9c-5f1b-11ec-9728-06d158a16ffc">column</a>.`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since July.
      You have had more days when you felt good compared to last month. Do you have a way to relieve stress that fits you?`,
      down: `Your stress score has gone down %d pts since July.
      Do you have a way to relieve stress that fits you, such as doing frequent stretches or sleeping well?`,
      equal: `Your stress score is the same as July. 
      Do you have a way to relieve stress that fits you, such as doing frequent stretches or sleeping well?`,
    },
  },
}
